import React from 'react';
import UsersMain from './usersMain';
import TabbedPage from '../TabbedPage/tabbedPage';

const tabs = [
  {
    title: 'Browse Users',
    id: 'main',
    component: () => <UsersMain />
  }
];

export default () => {
  return (
    <div>
      <h1 className="main-pane-header">Users</h1>
      <TabbedPage tabs={tabs} defaultTab="main" />
    </div>
  );
}
