import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAzPjdK0cJ_rfL_Z9a2jjogthQoC9xpi1g',
  authDomain: 'ask-a-girl.firebaseapp.com',
  projectId: 'ask-a-girl',
  appId: '1:12460310988:web:04b59a5de18d99c6115d69'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
