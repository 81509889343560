import React, { useContext } from 'react';
import PreferencesProvider from '../../providers/Preferences/preferencesProvider';
import './mainNavbar.scss';

function MainNavbar() {

  const prefsCtx = useContext(PreferencesProvider.context) as any;

  return <div className="main-navbar">
    <a className="collapse" onClick={prefsCtx.actions.sidebarToggle} href="#" />
  </div>;
}

export default MainNavbar;
