import React from 'react';
import UserFeedback from './userFeedback';
import TabbedPage from '../TabbedPage/tabbedPage';

const tabs = [
  {
    title: 'User Feedback',
    id: 'user-feedback',
    component: () => <UserFeedback source="user" />
  },
  {
    title: 'Advisor Feedback',
    id: 'advisor-feedback',
    component: () => <UserFeedback source="advisor" />
  }
];

export default () => {
  return (
    <div>
      <h1 className="main-pane-header">Dashboard</h1>
      <TabbedPage tabs={tabs} defaultTab="user-feedback" />
    </div>
  );
}
