// make sure if we already encountered this viewModel in the past
// in our cache, not to REPLACE it, but instead to update that
// viewModel with a new underlying model value. This way
// any react components observing that viewModel will
// continue to observe changes on it

export default function updateCache(ViewModelClass: any, cache: any, value: any) {
  if (!cache[value.id]) return cache[value.id] = new ViewModelClass(value);
  cache[value.id].setModel(value);
  return cache[value.id];
}
