import firebase from './firebase';
import constants from '../common/constants';

export interface FeedbackModel {
  created_at: string,
  details: string,
  feedbacker: string,
  feedbackee: string,
  id: string,
  rating: any,
  reported: boolean,
  report_reason: string
};

interface Coordinate {
  longitude: Number,
  latitude: Number,
};

interface Location {
  id: string,
  name: string,
  coordinate: Coordinate,
};

enum BanType {
  permanent = "permanent",
  temporary = "temporary",
}

export class Ban {

  type: BanType
  expires_at: Date | null

  constructor(expires_at: Date | null) {
    this.expires_at = expires_at;
    if (null == expires_at) {
      this.type = BanType.permanent;
    } else {
      this.type = BanType.temporary;
    }
  }

}

export interface AdvisorApplicationModel {
  id: string,
  user_id: string,
  government_id_image_url: string,
  tax_form_image_url: string,
  created_at: string,
}

export interface UserModel {
  id: string,
  full_name: string,
  created_at: string,
  email_address: string,
  location: Location,
  interests: string[],
  about_me: string,
  profile_image_urls: string[],
  ban: Ban | null,
}

export interface AdvisorApplicationModel {
  id: string,
  government_id_image_url: string,
  tax_form_image_url: string,
  created_at: string,
}

let jwt: string | null = null;

async function request(path: string, method: string, data: any = null): Promise<any> {
  if ('GET' === method && null != data) {
    const delim = constants.server.includes('?') ? '&' : '?';
    path += delim + Object.keys(data).reduce((h: any, k) => {
      if (undefined !== data[k]) h.push(`${k}=${encodeURIComponent(data[k])}`);
      return h;
    }, []).join('&');
  }
  let headers: any = {
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['x-askagirl-token'] = jwt;
  }
  return fetch(constants.server + path, {
    method,
    headers,
    mode: 'cors',
    body: (data && 'GET' != method) ? JSON.stringify(data) : null,
  })
  .then(response => response.json());
}

const get = async (path: string, data: any = null): Promise<any> => request(path, 'GET', data);
const post = async (path: string, data: any = null): Promise<any> => request(path, 'POST', data);
const del = async (path: string, data: any = null): Promise<any> => request(path, 'DELETE', data);


type FeedbackWithUsers = {
  users: UserModel[],
  feedback: FeedbackModel[]
};

type AdvisorApplicationsWithUsers = {
  applicants: UserModel[],
  applications: AdvisorApplicationModel[]
};

export default {

  getMemberAdvisorApplications: async (): Promise<AdvisorApplicationsWithUsers> => {
    const results = await get('/admin/pending-advisor-applications');
    return results;
  },

  getMemberFeedbacks: async (source: string, query?: string): Promise<FeedbackWithUsers> => {
    const results = await get('/admin/feedback', { query, source });
    return results;
  },

  getMemberFeedbackById: async (id: string): Promise<FeedbackModel> => {
    const results = await get(`/admin/feedback/${id}`);
    return results;
  },

  getUserById: async (id: string): Promise<UserModel> => {
    const results = await get(`/admin/users/${id}`);
    return results.user;
  },

  searchUsers: async (query?: string): Promise<UserModel> => {
    if (query == null || query == '') query = undefined;
    const results = await get('/admin/users', { query });
    return results.users;
  },

  // returns a token
  login: async (username: string, password: string): Promise<string> => {
    const attempt = await firebase.auth()
                      .signInWithEmailAndPassword(username, password);
    const token = await (firebase as any).auth().currentUser.getIdToken(true);
    try {
      const response = await post('/admin/firebase-id-tokens', { firebase_id_token: token });
      return response.jwt;
    } catch (error) {
      console.error('Login Error', error);
      throw new Error('Could not find your user.');
    }
  },

  ban: async (user_id: string, expires_at: Date): Promise<any> => {
    return await post('/admin/bans', { user_id, expires_at });
  },

  unban: async (user_id: string): Promise<any> => {
    return await del(`/admin/users/${user_id}/active-bans`, { user_id });
  },

  setJwt: (new_jwt: string) => {
    jwt = new_jwt;
  },

};
