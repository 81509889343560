import AskAGirl from '../../api/AskAGirl';

export interface SelfUserState {
  loggedIn: Boolean
  loginError: string
  loginLoading: Boolean
  jwt: string
}

export const actions = {

  initState: () => {
    if (!!localStorage.jwt) {
      AskAGirl.setJwt(localStorage.jwt);
    }
    return {
      loggedIn: !!localStorage.jwt,
      loginError: '',
      jwt: localStorage.jwt
    };
  },

  startLogin: () => Object({ type: 'start-login' }),

  login: async (username: string, password: string) => {
    try {
      const token = await AskAGirl.login(username, password);
      localStorage.jwt = token;
      AskAGirl.setJwt(token);
      return { type: 'set-login-token', token };
    } catch (error) {
      return { type: 'login-error', errorMessage: error.message };
    }
  },

};

export default actions;
