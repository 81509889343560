import React from 'react';
import './configable-table.scss'

type Props = {
  columns: { [key: string]: string | Function },
  visibleColumns: string[],
  rows: any[]
};

export default ({ columns, visibleColumns, rows }: Props) => {
  return <table className="configable-table">
    <thead>
      <tr key="header">
        {visibleColumns.map(col => (<td>{col}</td>))}
      </tr>
    </thead>
    <tbody>
      {rows.map(row => {
        return <tr key={row[columns.key as string]}>
          {visibleColumns.map(col => {
            const Key = columns[col]; // can either be a prop of the row/model Or a component
            return <td>{Key instanceof Function ? <Key data={row} /> : row[Key as string]}</td>;
          })}
        </tr>;
      })}
    </tbody>
  </table>;
}
