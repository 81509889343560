import React from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import './tabbedPage.scss';

interface Tab {
  title: string,
  id: string,
  component: any,
};

type RoutedTabsProps = { tabs: Tab[], defaultTab?: string };

const Header = ({ tabs, defaultTab }: RoutedTabsProps) => {
  const match = useRouteMatch();
  return (
    <div className="header-container">
      {tabs.map(tab => {
        const header = () => <h1>{tab.title}</h1>;
        return <div>
          {defaultTab === tab.id && <Route exact path={match.path} component={header} />}
          <Route path={[`${match.path}/${tab.id}`]} component={header} />
        </div>
      })}
    </div>
  );
};

const RoutedTabs = ({ tabs, defaultTab }: RoutedTabsProps) => {
  const match = useRouteMatch();
  return (
    <div className="display-tab">
      {tabs.map(tab => {
        return <div>
          {defaultTab === tab.id && <Route exact path={match.path} component={tab.component} />}
          <Route path={[`${match.path}/${tab.id}`]} component={tab.component} />
        </div>
      })}
    </div>
  );
};

type Props = { tabs: Tab[], defaultTab?: string };

export default ({ tabs, defaultTab }: Props) => {
  const match = useRouteMatch();
  return (
    <div className="tabbed-page">
      <Header tabs={tabs} defaultTab={defaultTab} />
      <div className="tab-names">
        {tabs.map(tab => {
          let tabMatch = useRouteMatch(`${match.path}/${tab.id}`);
          if (!tabMatch && defaultTab === tab.id) {
            const parentMatch = useRouteMatch(match.path);
            tabMatch = parentMatch && parentMatch.isExact;
          }
          const c = tabMatch ? 'active' : null;
          return <Link to={`${match.url}/${tab.id}`} className={c}>{tab.title}</Link>
        })}
      </div>
      <RoutedTabs tabs={tabs} defaultTab={defaultTab} />
    </div>
  );
};
