import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from 'react-router-dom';

import MainSidebar from './components/MainSidebar/mainSidebar';
import MainNavbar from './components/MainNavbar/mainNavbar';
import PreferencesProvider from './providers/Preferences/preferencesProvider';
import UsersProvider from './providers/Users/usersProvider';
import SelfUserProvider from './providers/SelfUser/selfUserProvider';
import FeedbackProvider from './providers/Feedback/feedbackProvider';
import AdvisorApplicationsProvider from './providers/AdvisorApplications/advisorApplicationsProvider';

import Dashboard from './components/Dashboard/dashboard';
import Users from './components/Users/users';
import AdvisorApplications from './components/AdvisorApplications/advisorApplications';
import LoginGuard from './components/Login/loginGuard';

function HomePage() {
  return <div>im home 😀</div>;
}

function Transactions() {
  return <div>Transactions</div>;
}

function SetupDashboard() {
  return <FeedbackProvider.Provider>
    <Dashboard />
  </FeedbackProvider.Provider>
}

function SetupAdvisorApplications() {
  return <AdvisorApplicationsProvider.Provider>
    <AdvisorApplications />
  </AdvisorApplicationsProvider.Provider>
}

function App() {
  return (
    <div className="App">
      <Router>
        <SelfUserProvider.Provider>
          <PreferencesProvider.Provider>
            <UsersProvider.Provider>
              <LoginGuard>
                <MainSidebar />
                <div className="right-side">
                  <MainNavbar />
                  <Switch>
                    <Route exact path="/">
                      <HomePage />
                    </Route>
                    <Route path="/dashboard">
                      <SetupDashboard />
                    </Route>
                    <Route path="/users">
                      <Users />
                    </Route>
                    <Route path="/applications">
                      <SetupAdvisorApplications />
                    </Route>
                    <Route exact path="/transactions">
                      <Transactions />
                    </Route>
                  </Switch>
                </div>
              </LoginGuard>
            </UsersProvider.Provider>
          </PreferencesProvider.Provider>
        </SelfUserProvider.Provider>
      </Router>
    </div>
  );
}

export default App;
