import React, { useContext } from 'react';
import SelfUserProvider from '../../providers/SelfUser/selfUserProvider';
import Login from './login';

export default ({ children }: any) => {
  const prefs = useContext(SelfUserProvider.context) as any;
  if (!prefs.state.loggedIn) {
    return <Login />;
  }
  return <div className="login-guard">{children}</div>;
};
