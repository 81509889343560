import { Ban, AdvisorApplicationModel } from '../api/AskAGirl';
import UserViewModel from './UserViewModel';
import formatDate from '../utilities/formatDate';

export default class FeedbackViewModel {

  model: AdvisorApplicationModel;

  constructor(model: AdvisorApplicationModel) {
    this.model = model;
  }

  // MARK: default


  get govId(): string { return this.model.government_id_image_url }
  get taxForm(): string { return this.model.tax_form_image_url }

  get created(): Date { return new Date(this.model.created_at) }

  get user_id(): string { return this.model.user_id.toString() };
  get id(): string { return this.model.id.toString() };

  setModel(model: AdvisorApplicationModel) {
    this.model = model;
  }

}
