import { Ban, UserModel } from '../api/AskAGirl';
import formatDate from '../utilities/formatDate';

export default class UserViewModel {

  private model: UserModel;

  constructor(model: UserModel) {
    this.model = model;
  }

  // MARK: default

  get email(): string { return this.model.email_address }
  get location(): string { return this.model.location.name }
  get bio(): string { return this.model.about_me }
  get interests(): string { return this.model.interests.join(', ') }

  get photo(): string { return this.model.profile_image_urls[0] }

  get created(): Date { return new Date(this.model.created_at) }
  get name(): String { return this.model.full_name }

  // MARK: custom

  get createdAsString(): string {
    const date = this.created || new Date(0);
    return (date as any).toString().match(/\w+ (\w+ \d+ \d+)/)[1] || '';
  }

  get id(): string { return this.model.id.toString() };

  get banned(): boolean {   
    return (null != this.model.ban);
  }

  get bannedUntil(): Date | null {
    if (null == this.model.ban) {
      return null;
    } else {
      return new Date(String(this.model.ban.expires_at));
    }
  }

  get bannedUntilAsString(): string {
    if (false === this.banned) {
      return 'not banned';
    }
    if (null == this.bannedUntil) {
      return 'forever';
    } else {
      console.log(this.model, this.bannedUntil);
      return formatDate(this.bannedUntil);
    }
  }


  setModel(model: UserModel) {
    this.model = model;
  }

  markBanned(until: Date | null) { 
    this.model.ban = new Ban(until);
  }

  markUnbanned() { 
    this.model.ban = null; 
  }

}
