declare var Proxy: any;

export default (array: any, indexed: any, foreignKey: string) => {
	return array.map((row: any) => {
		return new Proxy(row, {
			get: (target: any, key: any) => {
				if (row[key]) return row[key];
				const id = row[foreignKey];
				const model = indexed[id];
				if (!model) return undefined;
				return model[key];
			}
		});
	});
};


/*

combines array of objects with indexed array of objects without mutating any objct, but if the original object changes so too does the returned object here. I.e.:

var a = [
{user_id: 1, name: 'bob'},
{user_id: 2, name: 'bill'},
	
];

var indexed = {
  '1': { age: 55 },
  '2': { age: 3 },
};

const rows = combine(a, indexed, 'user_id');

rows[0] is { user_id: 1, name: 'bob', age: 55 }

if I were to change a[0].name = 'alex' then rows[0] would automatically be updated too!

*/
