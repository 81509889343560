import React from 'react';
import UserViewModel from '../../viewModels/UserViewModel';

type Props = { user: UserViewModel };

export default ({ user }: Props) => {
  return <div>
    <div className="icon email row">
      {user.email}
    </div>
    <div className="icon location row">
      {user.location}
    </div>
    <div className="icon interests row">
      {user.interests}
    </div>
    <div className="icon bio row">
      {user.bio}
    </div>
  </div>;
};
