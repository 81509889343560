export interface PreferencesState {
  sidebar: {
    visible: Boolean
  }
}

export const actions = {

  initState: () => {
    return {
      sidebar: {
        visible: true
      }
    };
  },

  sidebarToggle: () => {
    return { type: 'sidebar-toggle' };
  },

};

export default actions;
