import React, { useContext, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import UsersProvider from '../../providers/Users/usersProvider';
import AdvisorApplicationsProvider from '../../providers/AdvisorApplications/advisorApplicationsProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import TabbedPage from '../TabbedPage/tabbedPage';
import UserCard from '../UserCard/userCard';
import MainInfo from '../UserCard/mainInfo';
import UserViewModel from '../../viewModels/UserViewModel';
import BanUser from '../UserCard/banUser';
import combine from '../../utilities/combine';
import './advisorApplications.scss';

const Popover = () => {
  const match = useRouteMatch();
  const id = match.params.id;
  const advisorApplications = useContext(AdvisorApplicationsProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  const application = advisorApplications.state.cached[id];
  useEffect(() => application ? users.actions.voidGetById(application.user_id) : undefined, [id, application]);
  if (!application) return null;
  const model = users.state.cached[application.user_id];
  if (!model) return null;
  const viewTax = () => window.open(application.taxForm);
  const viewGovID = () => window.open(application.govId);
  return <UserCard type="purple" title={model.name} image={model.photo}>
    {model.reported && !model.banned && <div className="report-reason">Reported for {model.reportedReason}</div>}
    {model.banned && <div className="report-reason">Banned until {model.bannedUntilAsString}</div>}
    <br />
    <MainInfo user={model} />
    <div className="buttons row">
      <button className="info" onClick={viewTax}>View Tax Form</button>
      <button className="info" onClick={viewGovID}>View Gov ID</button>
    </div>
    <BanUser user={model} />
  </UserCard>;
};

const Actions = ({ data }: { data: UserViewModel }) => {
  const match = useRouteMatch();
  return <div className="advisor-actions">
      <Link to={`${match.path}/actions/${data.id}`}>
        <button className="std-button">View</button>
      </Link>
      <button className="std-button">👍</button>
      <button className="std-button">👎</button>
    </div>;
};

const columns = {
  Name: 'name',
  Email: 'email',
  Location: 'location',
  Created: 'createdAsString',
  key: 'id',
  Actions: Actions
};

const visibleColumns = [
  'Name', 'Email', 'Location', 'Created', 'Actions'
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const advisorApplications = useContext(AdvisorApplicationsProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  useEffect(() => advisorApplications.actions.voidFetchForFirstLoad(users), []);
  const rows = combine(advisorApplications.state.rows, users.state.cached, 'user_id');
  const match = useRouteMatch();
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search visitors"
               onChange={e => setSearch(e.target.value)} />
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={rows}
        />
      </div>
      <Route path={`${match.path}/actions/:id`} component={Popover} />
    </div>
  );
}
