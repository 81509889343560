import React, { useContext } from 'react';
import UsersProvider from '../../providers/Users/usersProvider';
import UserViewModel from '../../viewModels/UserViewModel';

const FOREVER = 86400000 * 9999;

const promptForTime = (): Date => {
  const days = Number(prompt('How many days would you like to ban them for?', '3'));
  if (isNaN(days)) {
    alert('You did not specify a valid number!');
    throw new Error('BAD_NUMBER');
  }
  return new Date(Date.now() + (86400000*days));
};

export default ({ user }: { user: UserViewModel }) => {
  const usersContext = useContext(UsersProvider.context) as any;
  const banUser = () => usersContext.actions.voidBan(user.id, new Date(Date.now() + FOREVER));
  const suspendUser = () => usersContext.actions.voidBan(user.id, new Date(promptForTime()));
  const unbanUser = () => usersContext.actions.voidUnban(user.id);
  return (
      <div className="buttons row">
        {!user.banned && <button className="danger ban-btn" onClick={banUser}>Ban</button>}
        {!user.banned && <button className="warning suspend-btn" onClick={suspendUser}>Suspend</button>}
        {user.banned && <button className="warning suspend-btn" onClick={unbanUser}>Unban</button>}
      </div>
  );
};
