import AskAGirl from '../../api/AskAGirl';
import AdvisorApplicationsViewModel from '../../viewModels/AdvisorApplicationsViewModel';

export interface AdvisorApplicationsState {
  rows: AdvisorApplicationsViewModel[],
  cached: { [id: string]: AdvisorApplicationsViewModel }
};

export default {

    initState: () => {
      return {
        cached: {},
        rows: []
      };
    },

    fetchForFirstLoad: async (userContext: any) => {
      const { applications, applicants } = await AskAGirl.getMemberAdvisorApplications();
      userContext.actions.acceptUsers(applicants);
      return { rows: applications, type: 'fetchForFirstLoad' };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      //const row = await AskAGirl.getMemberAdvisorApplicationsById(id);
      //return { rows: [row], type: 'getUsers' };
    },

};
