import AskAGirl from '../../api/AskAGirl';
import FeedbackViewModel from '../../viewModels/FeedbackViewModel';

export interface FeedbackState {
  rows: FeedbackViewModel[],
  cached: { [id: string]: FeedbackViewModel }
};

export default {

    initState: () => {
      return {
        cached: {},
        rows: []
      };
    },

    fetchForFirstLoad: async (source: string, userContext: any) => {
      const { feedback, users } = await AskAGirl.getMemberFeedbacks(source);
      userContext.actions.acceptUsers(users);
      return { rows: feedback, type: 'fetchForFirstLoad' };
    },

    search: async (text: string, source: string, userContext: any) => {
      const { feedback, users } = await AskAGirl.getMemberFeedbacks(source, text);
      userContext.actions.acceptUsers(users);
      return { rows: feedback, type: 'search' };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      const row = await AskAGirl.getMemberFeedbackById(id);
      return { rows: [row], type: 'getUsers' };
    },

    ban: async (banned_user_id: string, duration: Date): Promise<any> => {
      await AskAGirl.ban(banned_user_id, duration);
      return { ban: true, type: 'bannedUser', banned_user_id, duration }
    },

    unban: async (banned_user_id: string): Promise<any> => {
      await AskAGirl.unban(banned_user_id);
      return { type: 'unbannedUser', banned_user_id }
    },

};
