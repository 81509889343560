import AskAGirl, { UserModel } from '../../api/AskAGirl';
import UserViewModel from '../../viewModels/UserViewModel';

export interface UserState {
  cached: { [id: string]: UserViewModel },
  rows: UserViewModel[],
};

export default {

    initState: () => {
      return {
        rows: [],
        cached: {}
      };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      const row = await AskAGirl.getUserById(id);
      return { rows: [row], type: 'getUsers' };
    },

    searchUsers: async (query?: string) => {
      const rows = await AskAGirl.searchUsers(query);
      return { rows, type: 'searchUsers' };
    },

    ban: async (banned_user_id: string, duration: Date): Promise<any> => {
      await AskAGirl.ban(banned_user_id, duration);
      return { ban: true, type: 'bannedUser', banned_user_id, duration }
    },

    unban: async (banned_user_id: string): Promise<any> => {
      await AskAGirl.unban(banned_user_id);
      return { type: 'unbannedUser', banned_user_id }
    },

    acceptUsers: async(users: UserModel[]) => {
      return { rows: users, type: 'getUsers' };
    }

};
