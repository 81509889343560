import { PreferencesState } from './preferencesActions';

export default function(state: any, action: any): PreferencesState {
  state = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'initState':
      break;
    case 'sidebar-off':
      state.sidebar.visible = false;
      break;
    case 'sidebar-on':
      state.sidebar.visible = true;
      break;
    case 'sidebar-toggle':
      state.sidebar.visible = !state.sidebar.visible;
      break;
  }
  return state;
}
