import React, { useEffect } from 'react';
import classNames from 'classnames';
import './userCard.scss';
import { useHistory } from 'react-router-dom';

const goBack = (history: any, backUrl?: string) => {
  if (null != backUrl) {
    history.push(backUrl);
  } else {
    history.push(window.location.pathname.split('/actions/')[0]);
  }
}

type Props = { type: string, image: string, title: string, children?: any, backUrl?: string };

export default ({ type, image, title, children, backUrl }: Props) => {
  const history = useHistory();
  useEffect(() => {
    document.body.classList.add('fullscreen-content');
    return () => document.body.classList.remove('fullscreen-content');
  }, []);
  const className = classNames('user-card', type);
  const color = classNames('color', type);
  const backgroundImage = {
    backgroundImage: `url(${image})`
  };
  return (
    <div className="user-card-container">
      <div className={className}>
        <div className={color}>
          <div className="image" style={backgroundImage} />
        </div>
        <h1>{title}</h1>
        <div className="close" onClick={() => goBack(history, backUrl)}>x</div>
        <div className="body">
          {children}
        </div>
      </div>
    </div>
  );
};
