import { FeedbackState } from './feedbackActions';
import FeedbackViewModel from '../../viewModels/FeedbackViewModel';
import { FeedbackModel } from '../../api/AskAGirl';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, FeedbackViewModel);

export default function (state: FeedbackState, action: any): FeedbackState {
  const newState = { ...state };
  switch (action.type) {
    case 'search':
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: FeedbackModel) => cache(newState.cached, m));
      break;
    case 'getUsers':
      action.rows.forEach((m: FeedbackModel) => cache(newState.cached, m));
      break;
  }
  return newState;
}
