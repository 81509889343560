import React, { useRef, useContext } from 'react';
import SelfUserProvider from '../../providers/SelfUser/selfUserProvider';
import './login.scss';

const ErrorBox = ({ message }: { message?: string }) => {
  if (!message) return null;
  return <div className="error-box">{message}</div>;
}

export default () => {
  const selfUserCtx = useContext(SelfUserProvider.context) as any;
  const username = useRef(null);
  const password = useRef(null);
  const login = async () => {
    if (selfUserCtx.state.loginLoading) return;
    let user = (username?.current as any).value;
    let pass = (password?.current as any).value;
    selfUserCtx.actions.startLogin();
    await new Promise(r => setTimeout(r, 1000));
    selfUserCtx.actions.login(user, pass);
  };
  return <div className="login-pane">
    <div className="logo">AskAGirl</div>
    <ErrorBox message={selfUserCtx.state.loginError} />
    <input ref={username} placeholder="Username" type="text" />
    <input ref={password} placeholder="Password" type="password" />
    <button className="std-button logo-color wide" onClick={login}>
        {selfUserCtx.state.loginLoading ? 'Please Wait' : 'Login'}
    </button>
  </div>;
};
