import { AdvisorApplicationsState } from './advisorApplicationsActions';
import AdvisorApplicationsViewModel from '../../viewModels/AdvisorApplicationsViewModel';
import { AdvisorApplicationModel } from '../../api/AskAGirl';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, AdvisorApplicationsViewModel);

export default function (state: AdvisorApplicationsState, action: any): AdvisorApplicationsState {
  const newState = { ...state };
  switch (action.type) {
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: AdvisorApplicationModel) => cache(newState.cached, m));
      break;
  }
  return newState;
}
