import { SelfUserState } from './selfUserActions';

export default function(state: any, action: any): SelfUserState {
  state = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'initState':
      break;
    case 'start-login':
      state.loginLoading = true;
      break;
    case 'set-login-token':
      state.loginToken = action.token;
      state.loggedIn = true;
      state.loginLoading = false;
      break;
    case 'login-error':
      state.loginError = action.errorMessage;
      state.loggedIn = false;
      state.loginLoading = false;
      break;
  }
  //state.loggedIn = true;
  console.log(state);
  return state;
}
